import '../App.css';
import '../assets/css/user.css'
import React from 'react';
import user from '../assets/image/userfoto.svg';
import iconuser from '../assets/image/package-open.svg';
import settings from '../assets/image/usersettings.svg'
import { Link } from 'react-router-dom';
function User() {
    return (
        <>
            <div className="contnet_user">
                <div className="user_list">
                    <h1>Օգտատեր</h1>
                </div>
                <div className="container-fluid ">
                    <div className="row content_list">
                        <div className="col-xl-12 listStilinig">
                            <img src={user} alt="oneway" />
                            <Link to='/Անձնական տվյալներ'>
                                <span>Անձնական տվյալներ</span>
                            </Link>
                        </div>
                        <div className="col-xl-12 listStilinig">
                            <img src={iconuser} alt="oneway" />
                            <Link to='/Ընդունված առաքանիներ'>
                                <span>Ընդունված առաքանիներ</span>
                            </Link>
                        </div>
                        <div className="col-xl-12 listStilinig">
                            <img src={settings} alt="oneway" />
                            <Link to='/Կարգավորումներ'>
                                <span>Կարգավորումներ</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default User;