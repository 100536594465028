import '../App.css';
import React from 'react';
import rus from '../assets/image/russia.svg.svg';
import spain from '../assets/image/spain.svg.svg';
import grace from '../assets/image/grace.svg.svg';
import china from '../assets/image/chinas.svg';
import use from '../assets/image/flag-usa.svg';
import air from '../assets/image/airplane.svg';
function Tarrifs() {
    const items = [
        {
            imgSrc: use,
            text1: 'ԱՄՆ',
            imgSrc1: air,
            text2: '10-12 աշխ․ օր',
            text3: '5500 ՀՀ դրամ',
        },
        {
            imgSrc: rus,
            text1: 'Ռուսաստան',
            imgSrc1: air,
            text2: '8-10 աշխ․ օր',
            text3: '1000 ՀՀ դրամ',
        },
        {
            imgSrc: spain,
            text1: 'Իսպանիա',
            imgSrc1: air,
            text2: '12-15 աշխ․ օր',
            text3: '4800 ՀՀ դրամ',
        },
        {
            imgSrc: china,
            text1: 'Չինաստան',
            imgSrc1: air,
            text2: '25-30 աշխ․ օր',
            text3: '5000 ՀՀ դրամ',
        },
        {
            imgSrc: grace,
            text1: 'Հունաստան',
            imgSrc1: air,
            text2: '12-15 աշխ․ օր ',
            text3: '2800 ՀՀ դրամ',
        },

    ]
    return (
        <>
            <div className="content_container_section to_scrollbar">
                <h1>Սակագներ</h1>
                <div className="content_section">
                    <div className="traffic_section">
                        <div className="c1 c1_1">
                            <span>Երկրներ</span>
                            <span>Առաքման տևողություն</span>
                            <span>Սակագին 1կգ</span>
                        </div>
                        {items.map((item, index) => (
                            <div key={index} className="c1">
                                <div className='c1_flex'>
                                    <img src={item.imgSrc} alt={item.text1} className='img_srcFlag' />
                                    <p>{item.text1}</p>
                                </div>
                                <div className='c1_flex flex_start'>
                                    <img src={item.imgSrc1} alt={item.text2} />
                                    <p>{item.text2}</p>
                                </div>
                                <div>
                                    <p>{item.text3}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Tarrifs;
