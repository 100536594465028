import '../App.css';
import '../assets/css/aboute.css';
import React, { useState } from 'react';
import showhidden from '../assets/image/showhidden.svg';
import armenianflag from '../assets/image/armenianFlag.svg';
import russianflag from '../assets/image/russing.svg';
import vector from '../assets/image/chevron-down.svg';

function Registr() {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [currentCountry, setCurrentCountry] = useState('armenia'); 

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePhone = (phone) => {
        const phoneRegex = currentCountry === 'armenia' ? /^\d{8}$/ : /^\d{10}$/;
        return phoneRegex.test(phone);
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        if (!validateEmail(value)) {
            setEmailError('Լրացրեք վավեր էլ․հասցե։');
        } else {
            setEmailError('');
        }
    };

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        setPhone(value);

        if (!validatePhone(value)) {
            setPhoneError(currentCountry === 'armenia' ? 'Հեռախոսահամարը պետք է պարունակի 8 նիշ։' : 'Հեռախոսահամարը պետք է պարունակի 10 նիշ։');
        } else {
            setPhoneError('');
        }
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);

        if (value.length < 8) {
            setPasswordError('Գաղտնաբառը պետք է պարունակի առնվազն 8 նիշ:');
        } else {
            setPasswordError('');
        }
    };

    const handleConfirmPasswordChange = (e) => {
        const value = e.target.value;
        setConfirmPassword(value);

        if (value !== password) {
            setConfirmPasswordError('Գաղտնաբառերը չեն համընկնում։');
        } else {
            setConfirmPasswordError('');
        }
    };

    const handleCountryToggle = () => {
        if (currentCountry === 'armenia') {
            setCurrentCountry('russia');
            setPhone('+7');
        } else {
            setCurrentCountry('armenia');
            setPhone('+374');
        }
        setPhoneError('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let valid = true;

        if (!validateEmail(email)) {
            setEmailError('Լրացրեք վավեր էլ․հասցե։');
            valid = false;
        } else {
            setEmailError('');
        }

        if (!validatePhone(phone)) {
            setPhoneError(currentCountry === 'armenia' ? 'Հեռախոսահամարը պետք է պարունակի 8 նիշ։' : 'Հեռախոսահամարը պետք է պարունակի 10 նիշ։');
            valid = false;
        } else {
            setPhoneError('');
        }

        if (password.length < 8) {
            setPasswordError('Գաղտնաբառը պետք է պարունակի առնվազն 8 նիշ:');
            valid = false;
        } else {
            setPasswordError('');
        }

        if (confirmPassword !== password) {
            setConfirmPasswordError('Գաղտնաբառերը չեն համընկնում։');
            valid = false;
        } else {
            setConfirmPasswordError('');
        }

       
    };

    return (
        <div className='content_login'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xxl-10 col-xl-10 col-lg-8 col-md-12 col-sm-12 form_left1">
                        <div className="content_form">
                            <h1>Գրանցվել</h1>
                            <form onSubmit={handleSubmit}>
                                <div className="form_action">
                                    <label htmlFor="email">Էլ․ հասցե</label>
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Էլ․ հասցե"
                                        id="email"
                                        value={email}
                                        onChange={handleEmailChange}
                                        className={emailError ? 'error' : ''}
                                    />
                                    {emailError && <span className="error_message">{emailError}</span>}
                                </div>
                                <div className="form_action">
                                    <label htmlFor="phone">Հեռախոսահամար</label>
                                    <div className="content_anountriNumber">
                                        <div className="open_block" onClick={handleCountryToggle}>
                                            <img src={currentCountry === 'armenia' ? armenianflag : russianflag} alt="country flag" />
                                            <span>{currentCountry === 'armenia' ? '+374' : '+7'}</span>
                                            <img src={vector} alt="toggle" />
                                        </div>
                                        <input
                                            type="text"
                                            name="phone"
                                            id="phone"
                                            onChange={handlePhoneChange}
                                            className={phoneError ? 'error' : ''}
                                        />
                                    </div>
                                    {phoneError && <span className="error_message">{phoneError}</span>}
                                </div>
                                <div className="form_action">
                                    <label htmlFor="password">Գաղտնաբառ</label>
                                    <div className="password_container">
                                        <input
                                            type={passwordVisible ? 'text' : 'password'}
                                            name="password"
                                            placeholder="Գաղտնաբառ"
                                            id="password"
                                            value={password}
                                            onChange={handlePasswordChange}
                                            className={passwordError ? 'error' : ''}
                                        />
                                        <img
                                            src={showhidden}
                                            alt="Toggle visibility"
                                            onClick={togglePasswordVisibility}
                                            className="toggle_password"
                                        />
                                    </div>
                                    {passwordError && <span className="error_message">{passwordError}</span>}
                                </div>
                                <div className="form_action">
                                    <label htmlFor="confirmPassword">Կրկնել գաղտնաբառը</label>
                                    <div className="password_container">
                                        <input
                                            type={confirmPasswordVisible ? 'text' : 'password'}
                                            name="confirmPassword"
                                            placeholder="Կրկնել գաղտնաբառը"
                                            id="confirmPassword"
                                            value={confirmPassword}
                                            onChange={handleConfirmPasswordChange}
                                            className={confirmPasswordError ? 'error' : ''}
                                        />
                                        <img
                                            src={showhidden}
                                            alt="Toggle visibility"
                                            onClick={toggleConfirmPasswordVisibility}
                                            className="toggle_password"
                                        />
                                    </div>
                                    {confirmPasswordError && <span className="error_message">{confirmPasswordError}</span>}
                                </div>
                                <div className="form_action">
                                    <button type="submit" className='submit1'>Գրանցվել</button>
                                </div>
                            </form>
                        </div>
                        <div className="form_image1"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Registr;
