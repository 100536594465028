import { Link } from 'react-router-dom';
import '../App.css';
import '../assets/css/user.css'
import React from 'react';
import slug from '../assets/image/slug.svg';
import group from '../assets/image/Group.svg';
import air from '../assets/image/airplane.svg';
import rectangle from '../assets/image/Rectangle 3.svg';
import chinas from '../assets/image/chinas.svg fill.svg';
import spin from '../assets/image/spain.svg.svg'
function Deliveries() {
    return (
        <>
            <div className="content_deliverList">
                <Link to='/Օգտատեր'>
                    <div className="to_sec">
                        <img src={slug} alt="oneway" />
                        <span>Օգտատեր</span>
                    </div>

                </Link>
            </div>
            <div className="content_wrapperDeliv">
                <h1>Ընդունված առաքանիներ</h1>
            </div>
            <div className="container-fluid">
                <div className="row content_rowing">
                    <div className="col-xl-12 listTO">
                        <Link to='/Ընդունված առաքանիներ1'>
                            <div className="content_top">
                                <span>YT343EN3334</span>
                                <div className="contentImage">
                                    <img src={group} alt="oneway" />
                                    <span>0,2կգ</span>
                                </div>
                            </div>
                            <div className="content_bottom">
                                <div className="contentNw">
                                    <img src={air} alt="oneway" />
                                    <span id='line'></span>
                                    <img src={chinas} alt="" className='contry'/>
                                    <span>Չինաստան</span>
                                </div>
                                <div className="contentMony">
                                    <span>1000դր</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-xl-12 listTO">
                    <Link to='/Ընդունված առաքանիներ1'>
                            <div className="content_top">
                                <span>YT343EN3334</span>
                                <div className="contentImage">
                                    <img src={group} alt="oneway" />
                                    <span>0,1կգ</span>
                                </div>
                            </div>
                            <div className="content_bottom">
                                <div className="contentNw">
                                    <img src={air} alt="oneway" />
                                    <span id='line'></span>
                                    <img src={spin} alt="" className='contry' />
                                    <span>Իսպանիա</span>
                                </div>
                                <div className="contentMony">
                                    <span>480դր</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-xl-12 listTO">
                    <Link to='/Ընդունված առաքանիներ1'>
                            <div className="content_top">
                                <span>YT343EN3334</span>
                                <div className="contentImage">
                                    <img src={group} alt="oneway" />
                                    <span>0,3կգ</span>
                                </div>
                            </div>
                            <div className="content_bottom">
                                <div className="contentNw">
                                    <img src={air} alt="oneway" />
                                    <span id='line'></span>
                                    <img src={spin} alt="" className='contry'/>
                                    <span>Իսպանիա</span>
                                </div>
                                <div className="contentMony">
                                    <span>1440դր</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Deliveries;