import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import '../assets/css/user.css';
import slug from '../assets/image/slug.svg';
import bell from '../assets/image/bell.svg';
import lock from '../assets/image/lock-keyhole.svg';
import userX from '../assets/image/user-x.svg';
import X from '../assets/image/x.svg';
import showhidden from '../assets/image/showhidden.svg';

function Settings() {
    const [isMoved, setIsMoved] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // New state for delete modal
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    const handleRadioChange = (e) => {
        setSelectedOption(e.target.value);
    };
    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        if (e.target.value !== newPassword) {
            setErrorMessage('Գաղտնաբառերը չեն համընկնում:');
        } else {
            setErrorMessage('');
        }
    };

    const handleLinkClick = (e) => {
        e.preventDefault();
        setIsModalOpen(true);
    };

    const handleDeleteLinkClick = (e) => {
        e.preventDefault();
        setIsDeleteModalOpen(true); // Open delete modal
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false); // Close delete modal
    };

    const handleToggle = () => {
        setIsMoved(!isMoved);
    };

    const toggleShowPassword = (setShowFunction) => {
        setShowFunction(prevState => !prevState);
    };

    return (
        <>
            <div className="content_deliverList">
                <Link to='/Օգտատեր'>
                    <div className="to_sec">
                        <img src={slug} alt="oneway" />
                        <span>Օգտատեր</span>
                    </div>
                </Link>
            </div>
            <div className="content_wrapperDeliv">
                <h1>Կարգավորումներ</h1>
            </div>
            <div className="container-fluid ">
                <div className="row content_list">
                    <div className="col-xl-12 listStilinig">
                        <img src={bell} alt="oneway" />
                        <span>Ծանուցումներ</span>
                        <div className="wrapper_radio">
                            <div className="content_radio" onClick={handleToggle}>
                                <div className={`radioRadius ${isMoved ? 'move' : ''}`}></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 listStilinig">
                        <img src={lock} alt="oneway" />
                        <Link to='' onClick={handleLinkClick}>
                            <span>Փոխել գաղտնաբառը</span>
                        </Link>
                    </div>
                    {isModalOpen && (
                        <div className="modal-overlay" onClick={closeModal}>
                            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                                <div className="conteent_close">
                                    <img src={X} alt="oneway" onClick={closeModal} />
                                </div>
                                <div className="content_textList">
                                    <p>Փոխել գաղտնաբառը</p>
                                </div>
                                <div className="content_pass">
                                    <div className="pass_form">
                                        <label htmlFor="oldPassword">Հին գաղտնաբառ</label>
                                        <input
                                            type={showOldPassword ? 'text' : 'password'}
                                            id="oldPassword"
                                            value={oldPassword}
                                            onChange={(e) => setOldPassword(e.target.value)}
                                        />
                                        <img
                                            src={showhidden}
                                            alt="Show/Hide"
                                            onClick={() => toggleShowPassword(setShowOldPassword)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </div>
                                    <div className="pass_form">
                                        <label htmlFor="newPassword">Նոր գաղտնաբառ</label>
                                        <input
                                            type={showNewPassword ? 'text' : 'password'}
                                            id="newPassword"
                                            value={newPassword}
                                            onChange={handleNewPasswordChange}
                                        />
                                        <img
                                            src={showhidden}
                                            alt="Show/Hide"
                                            onClick={() => toggleShowPassword(setShowNewPassword)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </div>
                                    <div className="pass_form">
                                        <label htmlFor="confirmPassword">Կրկնել նոր գաղտնաբառը</label>
                                        <input
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            id="confirmPassword"
                                            value={confirmPassword}
                                            onChange={handleConfirmPasswordChange}
                                        />
                                        <img
                                            src={showhidden}
                                            alt="Show/Hide"
                                            onClick={() => toggleShowPassword(setShowConfirmPassword)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                                    </div>
                                </div>
                                <div className="modal_button">
                                    <button onClick={closeModal}>Փակել</button>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="col-xl-12 listStilinig">
                        <img src={userX} alt="oneway" />
                        <Link to='' onClick={handleDeleteLinkClick}>
                            <span>Ջնջել հաշիվը</span>
                        </Link>
                    </div>
                    {isDeleteModalOpen && (
                        <div className="modal-overlay" onClick={closeDeleteModal}>
                            <div className="modal-content count_model" onClick={(e) => e.stopPropagation()}>
                                <div className="conteent_close">
                                    <img src={X} alt="oneway" onClick={closeDeleteModal} />
                                </div>
                                <div className="content_textList">
                                    <p>Ջնջել հաշիվը</p>
                                </div>
                                <div className="contaner_deleteText">
                                    <p>Որոշել եք ջնջե՞լ Ձեր հաշիվը</p>
                                </div>
                                <div className="content_cvhecked">
                                    <div className="input_check">
                                        <label htmlFor="noPurchases">Գնումներ չեմ կատարելու</label>
                                        <input
                                            type="radio"
                                            id="noPurchases"
                                            name="feedback"
                                            value="noPurchases"
                                            checked={selectedOption === 'noPurchases'}
                                            onChange={handleRadioChange}
                                        />
                                    </div>
                                    <div className="input_check">
                                        <label htmlFor="serviceIssue">Սպասարկումը չի գոհացրել</label>
                                        <input
                                            type="radio"
                                            id="serviceIssue"
                                            name="feedback"
                                            value="serviceIssue"
                                            checked={selectedOption === 'serviceIssue'}
                                            onChange={handleRadioChange}
                                        />
                                    </div>
                                    <div className="input_check checTo">
                                        <label htmlFor="other">Այլ</label>
                                        <input
                                            type="radio"
                                            id="other"
                                            name="feedback"
                                            value="other"
                                            checked={selectedOption === 'other'}
                                            onChange={handleRadioChange}
                                        />
                                       
                                        
                                    </div>
                                    <>
                                        {selectedOption === 'other' && (
                                            <textarea
                                                id="otherDetails"
                                                placeholder="Խնդրում եմ նշեք պատճառը"
                                            ></textarea>
                                        )}
                                        </>
                                </div>
                                <div className="modal_button">
                                    <button onClick={closeDeleteModal}>Ջնջել</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Settings;
