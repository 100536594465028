import '../App.css';
import React from 'react';

function PrivatePolice() {
    return (
        <>
            <div className="contanet_privatepo">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text_police">
                                <h1>Գաղտնիության քաղաքականություն</h1>
                            </div>
                        </div>
                        <div className="col-lg-12 toTextName">
                            <span>Սույն «Գաղտնիության Քաղաքականությունը տարածվում է բոլոր այն այցելուների, օգտատերերի և այլոց (այսուհետ` “Յուրաքանչյուր ոք”, “Օգտատեր”, “Դուք” կամ “Ձեր”) վրա, ովքեր օգտվում են մեր կայքի ծառայություններից կամ նման ծառայություններից օգտվելու իրավունք ունեն: </span>
                        </div>
                        <div className="col-lg-12 mt-5 toTextName">
                            <span>
                                Սույն Գաղտնիության Քաղաքականությունը կարգավորում է www.oneway.am կայքի (այսուհետ` “Կայք”) օգտատերերից ստացված տեղեկատվության հավաքագրման, կիրառման, պահպանման և բացահայտման եղանակները:
                            </span>
                        </div>
                        <div className="col-lg-12 mt-5 toTextName">
                            <span>
                                Սույն Գաղտնիության Քաղաքականությունը կիրառվում է կայքերի, ապրանքների, ծառայությունների և «Oneway» ընկերության (այսուհետ՝ ՛՛Ընկերություն՛՛) կողմից հասանելի ցանկացած այլ ծրագրակազմերի նկատմամբ:
                            </span>
                        </div>
                        <div className="col-lg-12 mt-5 toTextName">
                            <span>
                                Սույն Գաղտնիութան Քաղաքականությամբ սահմանվում են մեր կայքում մատուցվող ծառայություններից օգտվելիս ծագող Ձեր իրավունքները և պարտականություները: Եթե Դուք համաձայն չեք սույն Գաղտնիության Քաղաքականությամբ սահմանված պայմանների հետ, ապա Դուք իրավունք չունեք օգտվել սույն կայքում մատուցվող ծառայություններից: Շարունակելով օգտվել սույն կայքում մատուցվող ծառայություններից Դուք տալիս եք համաձայնություն Ընկերության կողմից սույն  քաղաքականությամբ (անձնական տվյալների մշակման ծանուցմամբ) սահմանված կարգով Ձեր անձնական տվյալների մշակումն, այդ թվում՝ անձնական տվյալների հարցումն իրականացնելու համար:
                            </span>
                        </div>
                    </div>
                    
                        <div className="col-lg-12 newTextPolice mt-5">
                            <h1>1. Անձնական տվյալներ </h1>
                        </div>
                        <div className="col-lg-12 mt-5 toTextName">
                            <span>Դուք կարող եք այցելել մեր կայք առանց գրանցվելու, սակայն կայքի և Ընկերության ամբողջական ծառայություններից օգտվելու համար Դուք պարտավոր եք գրանցվել կայքում: Անձնական տեղեկությունները հավաքագրվում են (ստացվում են) միայն այն ժամանակ, երբ Դուք դրանք տրամադրում եք: Գրանցվելիս Դուք պետք է տրամադրեք Ձեր անունը, հասցեն, էլ. փոստի հասցեն և կոնտակտային տվյալները:</span>
                        </div>
                        <div className="col-lg-12 mt-5 toTextName to_padding">
                            <span>
                            Երբ Դուք օգտվում եք մեր կայքից, մենք կարող ենք ստանալ տեղեկություններ նաև այլ եղանակներով: Մեր սերվերները Ձեր մասին ստանում և հավաքում են այնպիսի տեղեկություններ, ինչպիսիք են Ձեր IP հասցեն, բրաուզերի անվանումը, համակարգչի տեսակը, տեխնիկական տվյալներ օգտատերերի վերաբերյալ և մեր կայքի հետ կապ հաստատելու միջոցները, ինչպիսիք են օպերացիոն համակարգի տեսակը, ինտերնետային ծառայություն տրամադրող կազմակերպության անունը և այլ համանման տեղեկություններ: Այս տեղեկությունները օգնում են մեզ տեղյակ լինել և հասկանալ մեր օնլայն օգտվողների վարքագիծն ու միտումները: Մենք նաև ստանում ենք տեղեկություններ մեր կայքում Ձեր գործունեության վերաբերյալ: Այդ տեղեկությունները թույլ են տալիս առաջարկել Ձեզ ավելի հարմարեցված և Ձեր կողմից ավելի գերադասելի ծառայություններ: Ձեր գրանցման պահին մենք ստանում ենք Ձեր տրամադրած տեղեկությունը, օրինակ Ձեր էլ. հասցեն, գաղտնաբառը և այլն:

                            </span>
                        </div>
                  
                </div>
            </div>
        </>
    )
}
export default PrivatePolice;