import './App.css';
import React from 'react';
import location from './assets/image/Pin.svg';
import mail from './assets/image/Email.svg';
import phone from './assets/image/Phone.svg';
import logo from './assets/image/mainLogo12.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import calcualtion from './assets/image/calculator.svg';
import vector from './assets/image/vector_top.svg';
import hub from './assets/image/Menu 2.svg';
import close from './assets/image/close.svg';
import rus from './assets/image/russia.svg.svg';
import spain from './assets/image/spain.svg.svg';
import grace from './assets/image/grace.svg.svg';
import china from './assets/image/chinas.svg';
import use from './assets/image/flag-usa.svg'
function Navbar() {
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };
    return (
        <div className='navbar'>
            <div className="wrapper-navbar">
                <div className="navbar_top">
                    <div className="nav_item">
                        <div className="item_left">
                            <div className="item_list">
                                <img src={location} alt="Oneway" />
                                <span>ք․Երևան, Բաշինջաղյան 198</span>
                            </div>
                            <div className="item_list">
                                <img src={mail} alt="Oneway" />
                                <span>info@oneway.am</span>
                            </div>
                            <div className="item_list">
                                <img src={phone} alt="Oneway" />
                                <span>
                                    <a href="tel:+37433798888">+374 33 79 88 88</a>
                                </span>
                            </div>
                        </div>
                        <div className="navbar_btn">
                            <Link to='./login' >
                                <button className='btn_login'>Մուտք</button>
                            </Link>
                            <Link to='./registr' >
                                <button className='btn_registr'>Գրանցվել</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="navbar_bot">
                    <div className="navbar_logo">
                        <Link to='/' >
                            <img src={logo} alt="Oneway" />
                        </Link>
                    </div>
                    <MenuNavbar />
                    <div className="content_lang">
                        <Example />
                        <div className="language-selector">
                            <div className="leng" onClick={toggleDropdown}>
                                <span>Հայ</span>
                                <img src={vector} alt="toggle" />
                            </div>
                            {isDropdownOpen && (
                                <div className="dropdown_lang1">
                                    <div className="dropdown-item11">Eng</div>
                                    <div className="dropdown-item11">Рус</div>
                                    <div className="dropdown-item11">Հայ</div>
                                </div>
                            )}
                        </div>
                        <div className="dropdown_open">
                            <BasicExample />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



function BasicExample() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);
    const closeDropdown = () => setIsOpen(false);
    const [selectedLang, setSelectedLang] = useState('Հայ');

    const handleLangClick = (lang) => {
        setSelectedLang(lang);
    };
    return (
        <div className="dropdown">
            <img
                src={hub}
                alt="oneway"
                className="dropdown-button"
                onClick={toggleDropdown}
            />
            {isOpen && (
                <div className="dropdown-content">
                    <div className="close_icon" onClick={closeDropdown}>
                        <img src={close} alt="close" />
                    </div>
                    <ul className="dropdown_navbar">
                        <li>
                            <Link to="/Մեր-մասին">Մեր մասին</Link>
                        </li>
                       
                        <li>
                            <Link to="/Սակագներ">Սակագներ</Link>
                        </li>
                        <li>
                            <Link to="/shops">Խանութներ</Link>
                        </li>
                    </ul>
                    <div className="dropdown_lang">
                        <span
                            className={selectedLang === 'Հայ' ? 'active' : ''}
                            onClick={() => handleLangClick('Հայ')}
                        >
                            Հայ
                        </span>
                        <span
                            className={selectedLang === 'Рус' ? 'active' : ''}
                            onClick={() => handleLangClick('Рус')}
                        >
                            Рус
                        </span>
                        <span
                            className={selectedLang === 'Eng' ? 'active' : ''}
                            onClick={() => handleLangClick('Eng')}
                        >
                            Eng
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
}





function MenuNavbar() {
    return (
        <>
            <div className="menu_navbar">
                <ul className="menu">
                    <li>
                        <Link to='/Մեր-մասին'>Մեր մասին</Link>
                    </li>
                   
                    <li>
                        <Link to='/Սակագներ'>Սակագներ</Link>
                    </li>
                    <li>
                        <Link to='/shops'>Խանութներ</Link>
                    </li>
                </ul>
            </div>
        </>
    )
}



function Example() {
    const [show, setShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleImageClick = (index) => {
        setSelectedImage(index);
    };

    return (
        <>
            <img className='calculation_btn' src={calcualtion} alt='oneway' onClick={handleShow} />

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <div className="calc">
                        <h1>Առաքման հաշվիչ</h1>
                    </div>
                    <div className="content_country">
                        {[
                            { src: rus, alt: 'rus' },
                            { src: spain, alt: 'spain' },
                            { src: use, alt: 'use' }, // Corrected from 'use'
                            { src: china, alt: 'china' },
                            { src: grace, alt: 'grace' },
                        ].map((image, index) => (
                            <div
                                key={index}
                                className={`country ${selectedImage === index ? 'selected' : ''}`}
                                onClick={() => handleImageClick(index)}
                            >
                                <img src={image.src} alt={image.alt} />
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer />
            </Modal>
        </>
    );

}


export default Navbar;