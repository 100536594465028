import React, { useState } from 'react';
import '../App.css';
import '../assets/css/shops.css';
import search from '../assets/image/search.svg';
import use from '../assets/image/flag-usa.svg';
import rus from '../assets/image/russia.svg.svg';
import spain from '../assets/image/spain.svg.svg';
import china from '../assets/image/chinas.svg';
import grecia from '../assets/image/grace.svg.svg';
function Prohibited() {
    const [activeButton, setActiveButton] = useState('Բոլորը');

    const handleButtonClick = (country) => {
        setActiveButton(country);
    };

    return (
        <>
            <div className="content_shops">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 shos_text to_privil">
                            <h1>Արգելված ապրանքատեսակներներ</h1>
                        </div>
                        <div className="col-lg-12 shops_form">
                            <form action="">
                                <div className="form_action_search">
                                    <img src={search} alt="oneway" className="search-icon" />
                                    <input type="search" placeholder="Որոնել" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 shops_contry priliv_contri">
                        <div
                            className={`content_btn_shops ${activeButton === 'Բոլորը' ? 'active' : ''}`}
                            onClick={() => handleButtonClick('Բոլորը')}
                        >
                            <span>Բոլորը</span>
                        </div>
                        <div
                            className={`content_btn_shops ${activeButton === 'ԱՄՆ' ? 'active' : ''}`}
                            onClick={() => handleButtonClick('ԱՄՆ')}
                        >
                            <span>ԱՄՆ</span>
                            <img src={use} alt="oneway" />
                        </div>
                        <div
                            className={`content_btn_shops ${activeButton === 'Ռուսաստան' ? 'active' : ''}`}
                            onClick={() => handleButtonClick('Ռուսաստան')}
                        >
                            <span>Ռուսաստան</span>
                            <img src={rus} alt="oneway" />
                        </div>
                        <div
                            className={`content_btn_shops ${activeButton === 'Իսպանիա' ? 'active' : ''}`}
                            onClick={() => handleButtonClick('Իսպանիա')}
                        >
                            <span>Իսպանիա</span>
                            <img src={spain} alt="oneway" />
                        </div>
                        <div
                            className={`content_btn_shops ${activeButton === 'Չինաստան' ? 'active' : ''}`}
                            onClick={() => handleButtonClick('Չինաստան')}
                        >
                            <span>Չինաստան</span>
                            <img src={china} alt="oneway" />
                        </div>
                        <div
                            className={`content_btn_shops ${activeButton === 'Հունաստան' ? 'active' : ''}`}
                            onClick={() => handleButtonClick('Հունաստան')}
                        >
                            <span>Հունաստան</span>
                            <img src={grecia} alt="oneway" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row row_privid">
                    <div className="col-lg-12 col-sm-12 mb-3 prohibited-item">
                        <h1>Ալկոհոլ, օծանելիք</h1>
                        <span>Ալկոհոլային խմիչքներ, օծանելիք (200մլ-ից ավել):</span>
                    </div>
                    <div className=" col-lg-12 col-sm-12 mb-3 prohibited-item">
                        <h1>Հոգեմետ նյութեր պարունակող նյութեր</h1>
                        <span>
                            Պարտադիր է, որ դեղերը չլինեն հոգեմետ և չպարունակեն թմրանյութ:<br /><br />
                            Հոգեմետ նյութեր չպարունակող դեղամիջոցներից թույլատրվում է առաքել 1 տարվա մեջ 5 տարբեր դեղամիջոցների՝ 
                            յուրաքանչյուրից 3 սպառողական փաթեթ։
                        </span>
                    </div>
                    <div className=" col-lg-12 col-sm-12 mb-3 prohibited-item">
                        <h1>Ռադիոակտիվ պարագաներ</h1>
                        <span>Ռադիոակտիվ պարագաներ</span>
                    </div>
                    <div className=" col-lg-12 col-sm-12 mb-3 prohibited-item">
                        <h1>Պայթունավտանգ նյութեր</h1>
                        <span>
                            Պայթունավտանգ, հրավտանգ, դյուրավառ նյութեր, քիմիական նյութեր (թունաքիմիկատներ), 
                            լվացքի փոշի, չոր սառույց (сухой лед), բենզին, մեթիլ սպիրտ, ացետոն, սոսինձ, սնդիկ, մագնիս, 
                            ֆոսֆոր, բրուցին, նիկոտին պարունակող սարքավորումներ, նյութեր և պարագաներ, խոշոր տեխնիկական սարքավորումներ, 
                            որոնք պարունակում են մեծ քանակությամբ մագնիսներ։
                        </span>
                    </div>
                    <div className="col-lg-12 col-sm-12 mb-5 prohibited-item">
                        <h1>Դյուրավառ նյութեր</h1>
                        <span>
                            Դյուրավառ նյութեր (ներքին այրման շարժիչներ, մեքենայի շարժիչներ, յուղեր, անտիֆրիզ)։
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Prohibited;