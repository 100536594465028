// CustomDropdown.jsx
import React, { useState } from 'react';
import '../assets/css/Personal.css'; // Add styles for your custom dropdown

const CustomDropdown = ({ label, options, selectedValue, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (value) => {
        onSelect(value);
        setIsOpen(false);
    };

    return (
        <div className="custom-dropdown">
            <label>{label}</label>
            <div className="dropdown-header" onClick={toggleDropdown}>
                <span>{selectedValue || label}</span>
                <div className={`dropdown-arrow ${isOpen ? 'open' : ''}`}></div>
            </div>
            {isOpen && (
                <div className="dropdown-list">
                    {options.map((option) => (
                        <div
                            key={option}
                            className="dropdown-item"
                            onClick={() => handleSelect(option)}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomDropdown;
