import '../App.css';
import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import img2 from '../assets/image/contry/Property 1=Group 1000001323.svg';
import img3 from '../assets/image/contry/Property 1=Group 1000001324.svg';
import img4 from '../assets/image/contry/Property 1=Group 1000001325.svg';
import img5 from '../assets/image/contry/Property 1=Group 1000001328.svg';
import img6 from '../assets/image/contry/Property 1=Group 1000001329.svg';
import martin1 from '../assets/image/with box 1.svg';
import martin2 from '../assets/image/Hand_wave 1.svg'
import martin3 from '../assets/image/show1 1.svg';
import rus from '../assets/image/russia.svg.svg';
import spain from '../assets/image/spain.svg.svg';
import grace from '../assets/image/grace.svg.svg';
import china from '../assets/image/chinas.svg';
import use from '../assets/image/flag-usa.svg';
import air from '../assets/image/airplane.svg';
import martin from '../assets/image/marinUse.svg';
import a1 from '../assets/image/a1.svg';
import a2 from '../assets/image/a2.svg';
import a3 from '../assets/image/a3.svg';
import shop1 from '../assets/image/shops/shop_1.svg.svg';
import shop2 from '../assets/image/shops/shop_2.svg.svg';
import shop3 from '../assets/image/shops/shop_3.svg.svg';
import shop4 from '../assets/image/shops/shop_4.svg.svg';
import shop5 from '../assets/image/shops/shop_5.svg.svg';
import shop6 from '../assets/image/shops/shop_6.svg.svg';
import shop7 from '../assets/image/shops/shop_7.svg.svg';
import shop8 from '../assets/image/shops/shop_8.svg.svg';
import shop9 from '../assets/image/shops/shop_9.svg.svg';
import shop10 from '../assets/image/shops/shop_10.svg.svg';
import stroe from '../assets/image/Store.svg';
import google from '../assets/image/google.svg';
import facebook from '../assets/image/facebook.svg';
import instagram from '../assets/image/instagram.svg';
function Home() {
  return (
    <>
      <CarouselSlide />
      <SectionOne />
      <SectionTarrifs />
      <HowToUse />
      <Delivery />
      <Shops />
      <DownloadApp />
    </>
  );
}


function CarouselSlide() {
  return (
    <>
      <Carousel data-bs-theme="dark">
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img2}
            alt="oneway"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img3}
            alt="oneway"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img4}
            alt="oneway"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img5}
            alt="oneway"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img6}
            alt="oneway"
          />
        </Carousel.Item>
      </Carousel>
    </>
  )
}



function SectionOne() {
  return (
    <>
      <div className="content_container_section">
        <h1>Ինչպես կատարել գնում</h1>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-4 d_centre">
              <img src={martin3} alt="oneway" />
              <p>Գրանցվեք կայքում և ստացեք առաքման հասցեն։</p>
            </div>
            <div className="col-lg-4 col-sm-4 d_centre">
              <img src={martin2} alt="oneway" />
              <p>Կատարեք գնում և
                օգտագործեք Ձեր հասցեն։</p>
            </div>
            <div className="col-lg-4 col-sm-4 d_centre">
              <img src={martin1} alt="oneway" />
              <p>Ստացեք Ձեր առաքանին հաշված օրերի ընթացքում։</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}



function SectionTarrifs() {
  const items = [
    {
      imgSrc: use,
      text1: 'ԱՄՆ',
      imgSrc1: air,
      text2: '10-12 աշխ․ օր',
      text3: '5500 ՀՀ դրամ',
    },
    {
      imgSrc: rus,
      text1: 'Ռուսաստան',
      imgSrc1: air,
      text2: '8-10 աշխ․ օր',
      text3: '1000 ՀՀ դրամ',
    },
    {
      imgSrc: spain,
      text1: 'Իսպանիա',
      imgSrc1: air,
      text2: '12-15 աշխ․ օր',
      text3: '4800 ՀՀ դրամ',
    },
    {
      imgSrc: china,
      text1: 'Չինաստան',
      imgSrc1: air,
      text2: '25-30 աշխ․ օր',
      text3: '5000 ՀՀ դրամ',
    },
    {
      imgSrc: grace,
      text1: 'Հունաստան',
      imgSrc1: air,
      text2: '12-15 աշխ․ օր ',
      text3: '2800 ՀՀ դրամ',
    },

  ]
  return (
    <>
      <div className="content_container_section to_scrollbar">
        <h1>Սակագներ</h1>
        <div className="content_section">
          <div className="traffic_section">
            <div className="c1 c1_1">
              <span>Երկրներ</span>
              <span>Առաքման տևողություն</span>
              <span>Սակագին 1կգ</span>
            </div>
            {items.map((item, index) => (
              <div key={index} className="c1">
                <div className='c1_flex'>
                  <img src={item.imgSrc} alt={item.text1} className='img_srcFlag' />
                  <p>{item.text1}</p>
                </div>
                <div className='c1_flex flex_start'>
                  <img src={item.imgSrc1} alt={item.text2} />
                  <p>{item.text2}</p>
                </div>
                <div>
                  <p>{item.text3}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}


function HowToUse() {
  return (
    <>
      <div className="containet_toUse">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-12 col-md-6 use_txt">
              <div className="content_use">
                <h1>Ինչպես օգտվել</h1>
                <p>Ինչպես կատարել առցանց գնումներ Չինաստանից
                  և ստանալ Հայաստանում։</p>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 col-md-6">
              <div className="toUse_martin">
                <img src={martin} alt="oneway" />
                <div className="iphone">
                  {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/d2TIhtABkGg?si=_cuyCXMx6WQEmjxj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


function Delivery() {
  const deliverImage = [
    a1, a2, a3
  ]
  return (
    <>
      <div className="content_container_section ">
        <h1>Առաքում ՀՀ տարածքում</h1>
        <div className="container">
          <div className="row ">
            {deliverImage.map((image, index) => (
              <div className="col-lg-4 col-sm-12 routhing" key={index}>
                <img src={image} alt={`Oneway ${index + 1}`} className='delivery_image' />
              </div>
            ))}
          </div>
        </div>
        <div className="del_ver">
          <p>* Առաքումը կատարվում է Հայփոստ-ի միջոցով </p>
        </div>
      </div>
    </>
  )
}



function Shops() {
  const shopsImage = [
    shop1, shop2, shop3, shop4, shop5, shop6, shop7, shop8, shop9, shop10
  ]
  return (
    <>
      <div className="content_container_section">
        <h1>Խանութներ</h1>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 colspan_image" >
              {shopsImage.map((image, index) => (
                <img src={image} alt={`Oneway ${index + 1}`} key={index} />
              ))}
            </div>
            <div className="col-lg-12 d_end">
              <Link to='/shops'>
                <button>Բոլոր խանութները </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}



function DownloadApp() {
  return (
    <>
      <div className="container_app">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col_gap">
              <div className="app_text">
                <h1>Ներբեռնե՛ք հավելվածը</h1>
              </div>
              <div className="app_ap">
                <Link to=''>
                  <img src={stroe} alt="oneway" />
                </Link>
                <Link to=''>
                  <img src={google} alt="oneway" />
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col_colum">
              <p>Հետևեք մեզ</p>
              <Link to='https://www.facebook.com/onewayyerevan'>
                <img src={facebook} alt="oneway" />
              </Link>
              <Link to='https://www.instagram.com/onewaylogistics_yerevan/'>
                <img src={instagram} alt="oneway" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home;