import './App.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import logo1 from './assets/image/logoaqua.svg';
import miniphone from './assets/image/miniphone.svg';
import miniemail from './assets/image/miniemail.svg';
import location from './assets/image/location.svg';
import clock from './assets/image/Clock.svg';
import miniclose from './assets/image/miniclose.svg';
import minon from './assets/image/minion.svg';
import minlock from './assets/image/minilock.svg';
import visa from './assets/image/viza.svg';
import master from './assets/image/master.svg';
import logo from './assets/image/mainLogo12.svg';

function SectionFooter() {
    return (
        <>
            <div className="container_footer">
                <div className="container_fluid">
                    <div className="row">
                        <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 footer_logo">
                            <img src={logo1} alt="oneway" />
                        </div>
                        <div className="col-xxl-3 col-xl-2 col-lg-2 col-md-6 col-sm-12 footer_order3">
                            <div className="footer_menuName">
                                <h1>Տեղեկատվություն</h1>
                            </div>
                            <ul className="menuHub">
                                <li>
                                    <Link to='/Մեր-մասին'>Մեր մասին</Link>
                                </li>
                             
                                <li>
                                    <Link to='/Սակագներ'>Սակագներ</Link>
                                </li>
                                <li>
                                    <Link to='/shops'>Խանութներ</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 footer_order1">
                            <div className="footer_menuName">
                                <h1>Կապ մեզ հետ</h1>
                            </div>
                            <div className="content_hub">
                                <div className="hub">
                                    <img src={miniphone} alt="oneway" />
                                    <div className="phone_number">
                                        <span>+374 33 79 88 88</span>
                                        <span> +374 60 77 10 10</span>
                                    </div>
                                </div>
                                <div className="hub">
                                    <img src={miniemail} alt="oneway" />
                                    <span>info@oneway.am</span>
                                </div>
                                <div className="hub">
                                    <img src={location} alt="oneway" />
                                    <span>ք․Երևան, Բաշինջաղյան 198</span>
                                </div>
                                <div className="hub">
                                    <img src={clock} alt="oneway" />
                                    <span>Երկ-Ուրբ - 10։30-19։00</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 footer_order2">
                            <div className="content_hub">
                                <div className="hub">
                                    <Link to='/prohibited'>
                                    <img src={miniclose} alt="oneway" />
                                    <span>Արգելված ապրանքատեսակներ</span>
                                    </Link>
                               
                                </div>
                                <div className="hub">
                                <Link to='/privatepolice'>
                                    <img src={minon} alt="oneway" />
                                    <span>Գաղտնիության քաղաքականություն</span>
                                    </Link>
                                </div>
                                <div className="hub">
                                <Link to='/conditions'>
                                    <img src={minlock} alt="oneway" />
                                    <span>Ընդհանուր դրույթներ և պայմաններ</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="content_pay">
                                <h1>Վճարման եղանակներ</h1>
                                <div className="pay_image">
                                    <img src={visa} alt="oneway" />
                                    <img src={master} alt="oneway" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Rezervid />
        </>
    )
}


function Rezervid(){
    return(
        <>
        <div className="container_rezerv">
            <p>©2024 OneWay Logistics LLC։ Բոլոր իրավունքները պահպանված են։ </p>
        </div>
        </>
    )
}
export default SectionFooter;