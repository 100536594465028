import '../App.css';
import '../assets/css/aboute.css';
import React, { useState } from 'react';
import showhidden from '../assets/image/showhidden.svg';
import { Link } from 'react-router-dom';

function Login() {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        if (!validateEmail(value)) {
            setEmailError('Լրացրեք վավեր էլ․հասցե։');
        } else {
            setEmailError('');
        }
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);

        if (value.length < 8) {
            setPasswordError('Գաղտնաբառը պետք է պարունակի առնվազն 8 նիշ:');
        } else {
            setPasswordError('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let valid = true;

        if (!validateEmail(email)) {
            setEmailError('Լրացրեք վավեր էլ․հասցե։');
            valid = false;
        } else {
            setEmailError('');
        }

        if (password.length < 8) {
            setPasswordError('Գաղտնաբառը պետք է պարունակի առնվազն 8 նիշ:');
            valid = false;
        } else {
            setPasswordError('');
        }

        
    };

    return (
        <div className='content_login'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xxl-6 col-xl-5 col-lg-5 col_backImage">
                        <div className="form_image"></div>
                    </div>
                    <div className="col-xxl-6 col-xl-5 col-lg-7 col-md-12 col-sm-12 form_rigth">
                        <div className="content_form">
                            <h1>Մուտքագրել տվյալները</h1>
                            <form onSubmit={handleSubmit}>
                                <div className="form_action">
                                    <label htmlFor="email">Էլ․ հասցե</label>
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Էլ․ հասցե"
                                        id="email"
                                        value={email}
                                        onChange={handleEmailChange}
                                        className={emailError ? 'error' : ''}
                                    />
                                    {emailError && <span className="error_message">{emailError}</span>}
                                </div>
                                <div className="form_action">
                                    <label htmlFor="password">Գաղտնաբառ</label>
                                    <div className="password_container">
                                        <input
                                            type={passwordVisible ? 'text' : 'password'}
                                            name="password"
                                            placeholder="Գաղտնաբառ"
                                            id="password"
                                            value={password}
                                            onChange={handlePasswordChange}
                                            className={passwordError ? 'error' : ''}
                                        />
                                        <img 
                                            src={showhidden}
                                            alt="Toggle visibility"
                                            onClick={togglePasswordVisibility}
                                            className="toggle_password"
                                        />
                                    </div>
                                    {passwordError && <span className="error_message">{passwordError}</span>}
                                </div>
                                <div className="form_toflex">
                                    <div className="form_left">
                                        <input type="checkbox" id="rememberMe" className="custom_checkbox" />
                                        <label htmlFor="rememberMe">Հիշել</label>
                                    </div>
                                    <div className="form_right">
                                        <Link to='/forgetPass'>
                                            <span>Մոռացե ՞լ եք գաղտնաբառը։</span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="form_action">
                                    <button type="submit" className='submit'>Մուտք</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
