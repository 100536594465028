import React from 'react';
import '../App.css';
import '../assets/css/aboute.css';
import icon1 from '../assets/image/fr.svg';
import icon2 from '../assets/image/frr.svg';
import icon3 from '../assets/image/frf.svg';
import aboutemartin from '../assets/image/aboutemartin.svg';


function Aboute() {
    return (
        <>
            <div className="container_aboute">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 aboute_text">
                            <h1>Մեր մասին</h1>
                            <p>OneWay-ը կամուրջ է Հայաստանում սպառողների և ԱՄՆ-ի, Չինաստանի, Իսպանիայի, Լյուքսեմբուրգի և Ռուսաստանի առցանց խանութների միջև: Չունենք մինիմալ կամ մաքսիմալ քաշ, հաշվարկում ենք ոչ թե ծավալով, այլ կիլոգրամներով։
                            </p>
                        </div>
                        <div className="col-lg-12 text_toOneway">
                            <span>OneWay-ն առաջարկում է՝</span>
                        </div>
                        <div className="col-lg-12 icon_aboute">
                            <div className="aboute_icon">
                                <img src={icon1} alt="oneway" />
                                <span>Ցածր գներ</span>
                            </div>
                            <div className="aboute_icon">
                                <img src={icon2} alt="oneway" />
                                <span>Արագ և անվտանգ առաքում
                                </span>
                            </div>
                            <div className="aboute_icon">
                                <img src={icon3} alt="oneway" />
                                <span>0% միջնորդավճար</span>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 about_back">
                            <img src={aboutemartin} alt="oneway" className='aboute_none'/>
                            <div className="content_textAboute">
                                <h1>Ո ՞վ է <span id='colorMartin'>Մարթին</span> </h1>
                                <p>Ես Մարթին եմ, OneWay-ի ճանապարհն անցնելու ենք միասին։ Ես շրջագայում եմ ամբողջ աշխարհով և հավաքելով ձեր պատվերները բեռնափոխադրում եմ Հայաստան AM: Լինում եմ Չինաստանում 🇨🇳 , արդեն շատ ընկերներ ունեմ այնտեղ: Ռուսաստանի 🇷🇺 Wildberries-ի պահեստում ինձ զգում եմ արդեն ինչպես տանը: Հասցրել եմ այցելել Եվրոպայի մի քանի երկրներ` Իսպանիա 🇪🇸 , Հունաստան🇬🇷 և Լյուքսեմբուրգ 🇱🇺 : Եվ չմոռանանք ԱՄՆ-ի մասին 🇺🇸 , որին մենք բոլորս միասին այդքան սպասում էինք:Իհարկե դեռ վայրերը շատ են, որ պետք է գնամ: Ես միշտ լսում և փորձում եմ իրականացնել ձեր ցանկությունները:</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                
            </div>
        </>
    )
}
export default Aboute;