import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './page/Home';
import Login from './page/Login';
import Registr from './page/Registr';
import Navbar from './Navbar';
import SectionFooter from './footer';
import ForgetPassword from './components/forgetPass';
import PrivatePolice from './components/Privacy';
import GeneralConditions from './components/GeneralConditions';
import Shops from './components/Shops';
import Prohibited from './components/Prohibited';
import Aboute from './components/Aboute';
import Tarrifs from './components/Tarrifs';
import User from './components/Use';
import Deliveries from './components/Deliveries';
import Accepted from './components/Accepted';
import Settings from './components/Settings';
import Personal from './components/Personal';

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/home' element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='/registr' element={<Registr />} />
        <Route path='/forgetPass' element={<ForgetPassword />} />
        <Route path='/privatepolice' element={<PrivatePolice />} />
        <Route path='/conditions' element={<GeneralConditions />} />
        <Route path='/shops' element={<Shops />} />
        <Route path='/prohibited' element={<Prohibited />} />
        <Route path='/Մեր-մասին' element={<Aboute />} />
        <Route path='/Սակագներ' element={<Tarrifs />} />
        <Route path='/Օգտատեր' element={<User />} />
        <Route path='/Ընդունված առաքանիներ' element={<Deliveries />} />
        <Route path='/Ընդունված առաքանիներ1' element={<Accepted />} />
        <Route path='/Կարգավորումներ' element={<Settings />} />
        <Route path='/Անձնական տվյալներ' element={<Personal />} />
      </Routes>
      <SectionFooter />
    </BrowserRouter>
  );
}

export default App;
