import React, { useState } from 'react';
import '../App.css';
import '../assets/css/shops.css';
import search from '../assets/image/search.svg';
import use from '../assets/image/flag-usa.svg';
import rus from '../assets/image/russia.svg.svg';
import spain from '../assets/image/spain.svg.svg';
import china from '../assets/image/chinas.svg';
import grecia from '../assets/image/grace.svg.svg';
import shop1 from '../assets/image/shops/shop_1.svg.svg';
import shop11 from '../assets/image/shops/shop_1.svg.svg';
import shop2 from '../assets/image/shops/shop_2.svg.svg';
import shop21 from '../assets/image/shops/shop_2.svg.svg';
import shop3 from '../assets/image/shops/shop_3.svg.svg';
import shop31 from '../assets/image/shops/shop_3.svg.svg';
import shop4 from '../assets/image/shops/shop_4.svg.svg';
import shop41 from '../assets/image/shops/shop_4.svg.svg';
import shop5 from '../assets/image/shops/shop_5.svg.svg';
import shop51 from '../assets/image/shops/shop_5.svg.svg';
import shop6 from '../assets/image/shops/shop_6.svg.svg';
import shop61 from '../assets/image/shops/shop_6.svg.svg';
import shop7 from '../assets/image/shops/shop_7.svg.svg';
import shop71 from '../assets/image/shops/shop_7.svg.svg';
import shop8 from '../assets/image/shops/shop_8.svg.svg';
import shop81 from '../assets/image/shops/shop_8.svg.svg';
import shop9 from '../assets/image/shops/shop_9.svg.svg';
import shop91 from '../assets/image/shops/shop_9.svg.svg';
import shop10 from '../assets/image/shops/shop_10.svg.svg';
import shop101 from '../assets/image/shops/shop_10.svg.svg';
function Shops() {
    const [activeButton, setActiveButton] = useState('Բոլորը');

    const handleButtonClick = (country) => {
        setActiveButton(country);
    };
    const shopsImage = [
        shop1, shop11, shop2, shop21, shop3, shop31, shop4, shop41, shop5, shop51, shop6, shop61, shop7, shop71, shop8, shop81, shop9, shop91, shop10, shop101
    ]

    return (
        <>
            <div className="content_shops">
                <div className="container_shops"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 shos_text">
                            <h1>Խանութներ</h1>
                            <p>Կատարիր առցանց գնումներ աշխարհի թոփ խանութներից</p>
                        </div>
                        <div className="col-lg-10 shops_form">
                            <form action="">
                                <div className="form_action_search">
                                    <img src={search} alt="oneway" className="search-icon" />
                                    <input type="search" placeholder="Որոնել" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 shops_contry">
                        <div
                            className={`content_btn_shops ${activeButton === 'Բոլորը' ? 'active' : ''}`}
                            onClick={() => handleButtonClick('Բոլորը')}
                        >
                            <span>Բոլորը</span>
                        </div>
                        <div
                            className={`content_btn_shops ${activeButton === 'ԱՄՆ' ? 'active' : ''}`}
                            onClick={() => handleButtonClick('ԱՄՆ')}
                        >
                            <span>ԱՄՆ</span>
                            <img src={use} alt="oneway" />
                        </div>
                        <div
                            className={`content_btn_shops ${activeButton === 'Ռուսաստան' ? 'active' : ''}`}
                            onClick={() => handleButtonClick('Ռուսաստան')}
                        >
                            <span>Ռուսաստան</span>
                            <img src={rus} alt="oneway" />
                        </div>
                        <div
                            className={`content_btn_shops ${activeButton === 'Իսպանիա' ? 'active' : ''}`}
                            onClick={() => handleButtonClick('Իսպանիա')}
                        >
                            <span>Իսպանիա</span>
                            <img src={spain} alt="oneway" />
                        </div>
                        <div
                            className={`content_btn_shops ${activeButton === 'Չինաստան' ? 'active' : ''}`}
                            onClick={() => handleButtonClick('Չինաստան')}
                        >
                            <span>Չինաստան</span>
                            <img src={china} alt="oneway" />
                        </div>
                        <div
                            className={`content_btn_shops ${activeButton === 'Հունաստան' ? 'active' : ''}`}
                            onClick={() => handleButtonClick('Հունաստան')}
                        >
                            <span>Հունաստան</span>
                            <img src={grecia} alt="oneway" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="content_index">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 colspan_image" >
                            {shopsImage.map((image, index) => (
                                <img src={image} alt={`Oneway ${index + 1}`} key={index} className='shosImage'/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Shops;
