import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import '../assets/css/user.css';
import '../assets/css/Personal.css';
import slug from '../assets/image/slug.svg';
import CustomDropdown from './CustomDropdown';
import armenianflag from '../assets/image/armenianFlag.svg';
function Personal() {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedCommunity, setSelectedCommunity] = useState('');
    const [selectedDocumentType, setSelectedDocumentType] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const handlePhoneNumberChange = (e) => {
        const inputValue = e.target.value;
        // Allow only digits
        if (/^\d*$/.test(inputValue)) {
            setPhoneNumber(inputValue);
        }
    };
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        validateEmail(e.target.value);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError('Լրացրեք վավեր էլ․հասցե։');
        } else {
            setEmailError('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Further submission logic here
    };

    return (
        <>
            <div className="content_deliverList">
                <Link to='/Օգտատեր'>
                    <div className="to_sec">
                        <img src={slug} alt="oneway" />
                        <span>Օգտատեր</span>
                    </div>
                </Link>
            </div>
            <div className="content_wrapperDeliv">
                <h1>Անձնական տվյալներ</h1>
            </div>
            <div className="wrapper_person">
                <form onSubmit={handleSubmit} className='form_content'>
                    <div className="form_person">
                        <label htmlFor="">Անուն</label>
                        <input type="text" />
                    </div>
                    <div className="form_person">
                        <label htmlFor="">Ազգանուն</label>
                        <input type="text" />
                    </div>
                    <div className="form_person">
                        <label htmlFor="">Էլ․ հասցե</label>
                        <input
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                        />
                        {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
                    </div>
                    <div className="form_person person_number">
                        <label htmlFor="phoneNumber">Հեռախոսահամար</label>
                        <div className="content_number">
                            <div className="newBlock">
                                <img src={armenianflag} alt="Armenian Flag" />
                                <span className="country_code">+374</span>
                            </div>
                            <input
                                type="text"
                                id="phoneNumber"
                                className="phone_input"
                                placeholder="93 000000"
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                                maxLength="8"
                            />
                        </div>
                    </div>
                    <div className="form_person">
                        <CustomDropdown
                            label="Փաստաթղթի տեսակ"
                            options={[
                                'ՀՀ անձնագիր',
                                'ՀՀ նույնականացման քարտ',
                                'ՀՀ կենսաչափական անձնագիր',
                                'Օտարերկրյա անձնագիր'
                            ]}
                            selectedValue={selectedDocumentType}
                            onSelect={setSelectedDocumentType}
                        />
                    </div>
                    <div className="form_person">
                        <label htmlFor="">Անձնագրի համար</label>
                        <input type="text" placeholder='Անձնագրի համար' />
                    </div>
                    <div className="form_person">
                        <CustomDropdown
                            label="Մարզ"
                            options={[
                                'Երևան',
                                'Արագածոտն',
                                'Արարատ',
                                'Արմավիր'
                            ]}
                            selectedValue={selectedRegion}
                            onSelect={setSelectedRegion}
                        />
                    </div>

                    <div className="form_person">
                        <CustomDropdown
                            label="Համայնք"
                            options={[
                                'Արաբկիր',
                                'Աջափնյակ',
                                'Ավան',
                                'Դավթաշեն'
                            ]}
                            selectedValue={selectedCommunity}
                            onSelect={setSelectedCommunity}
                        />
                    </div>

                    <div className="form_person">
                        <label htmlFor="">Գրանցման հասցե</label>
                        <input type="text" placeholder='Գրանցման հասցե' />
                    </div>
                    <div className="form_person">
                        <button type="submit">Պահպանել</button>
                    </div>
                </form>
            </div>
        </>
    );
}

export default Personal;
