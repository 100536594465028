import '../assets/css/aboute.css';

function ForgetPassword() {
    return (
        <div className="content_forgetPass">
            <div className="container_fluid">
                <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 forgate_txt">
                        <div className="content_formForget">
                            <form action="" className='form_action_forgetPass'>
                                <h1>Մոռացե ՞լ եք գաղտնաբառը</h1>
                                <div className="form_action">
                                    <label htmlFor="email">Էլ․ հասցե</label>
                                    <input type="text" id="email" placeholder='Էլ․ հասցե'/>
                                    <p>Մուտքագրեք համակարգում գրանցված էլ․ հասցեն, որին ուղարկվելու է վերականգնման հրահագները։</p>
                                </div>
                                <div className="form_action">
                                    <button type="submit" className='submit'>Վերականգնել</button>
                                </div>
                            </form>
                            <div className="forgetImage"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgetPassword;
