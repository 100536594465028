import { Link } from 'react-router-dom';
import '../App.css';
import '../assets/css/user.css'
import slug from '../assets/image/slug.svg';
import group from '../assets/image/Group.svg';
import air from '../assets/image/airplane.svg';
import chinas from '../assets/image/chinas.svg fill.svg';
import house from '../assets/image/house-plus.svg';
import planet from '../assets/image/plane-takeoff.svg';
import landing from '../assets/image/plane-landing.svg';
import block from '../assets/image/uuuu.svg';

function Accepted() {
    return (
        <>
            <div className="content_deliverList">
                <Link to='/Ընդունված առաքանիներ'>
                    <div className="to_sec">
                        <img src={slug} alt="oneway" />
                        <span>Ընդունված առաքանիներ</span>
                    </div>

                </Link>
            </div>
            <div className="content_accepted">
                <div className="contanet_wrap">
                    <div className="col-xl-12 listTO">
                        <div className="content_top">
                            <span>YT343EN3334</span>
                            <div className="contentImage">
                                <img src={group} alt="oneway" />
                                <span>0,2կգ</span>
                            </div>
                        </div>
                        <div className="content_bottom">
                            <div className="contentNw">
                                <img src={air} alt="oneway" />
                                <span id='line'></span>
                                <img src={chinas} alt="oneway " className='contry' />
                                <span>Չինաստան</span>
                            </div>
                            <div className="contentMony">
                                <span>1000դր</span>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 wrapper_deliv">
                                <div className="content_deliv">
                                    <img src={house} alt="oneway" />
                                    <div className="deliveInfo">
                                        <span>Հասել է պահեստ</span>
                                        <span>07/07/2024 </span>
                                    </div>
                                </div>
                                <div className="content_deliv">
                                    <img src={planet} alt="oneway" />
                                    <div className="deliveInfo">
                                        <span>Ճանապարհին է</span>
                                        <span>07/07/2024 </span>
                                    </div>
                                </div>
                                <div className="content_deliv">
                                    <img src={landing} alt="oneway" />
                                    <div className="deliveInfo">
                                        <span>Հայաստանում է</span>
                                        <span>07/07/2024 </span>
                                    </div>
                                </div>
                                <div className="content_deliv">
                                    <img src={block} alt="oneway" />
                                    <div className="deliveInfo">
                                        <span>Ընդունված է</span>
                                        <span>07/07/2024 </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Accepted;